import React, { Component } from 'react';
import Header from '../components/layout/Header';
import PageHeader from "../components/layout/PageHeader";
import { Footer } from '../components/layout/Footer';

export class Telecommunications_media_technology extends Component {
    render() {
        return (

            <div className="site-main">
                <Header/>
            
                {/* PageHeader */} 
                <PageHeader
                    title="Telecom, Media & Technology Recruitment Solutions"
                    breadcrumb="Industries"
                />
                {/* PageHeader end */}  


                <div className="ttm-row sidebar ttm-sidebar clearfix">
                    <div className="container">
                        {/* row */}
                        <div className="row">
                            <div className="col-lg-8 content-area order-lg-2">
                                {/* ttm-service-single-content-area */}
                                <div className="ttm-service-single-content-area">
                                    <div className="ttm_single_image-wrapper">
                                        <img className="img-fluid" src="images/industries/telecommunications_media_technology.jpg" alt="services-img" />
                                    </div>
                                    <div className="ttm-service-description mt-40">
                                        <h3 className="mb-20">Telecom, Media & Technology Recruitment Solutions</h3>
                                        <p>In the rapidly evolving landscape of telecommunications, media, and technology, Digital Spidy emerges as a premier provider of bespoke recruitment solutions, adept at addressing the dynamic needs of this industry. As telecommunications undergoes a transformative convergence of information content and seamless delivery, we are steadfast in facilitating this journey.</p>
                                        <p>Our commitment extends to understanding the crucial aspects of capital investment, cost optimization, and strategic partnerships with innovative telecom firms to efficiently create and deliver content.</p>
                                        <p>Digital Spidy specializes in sourcing top-tier talent with a robust background in telecommunications. From networking hardware to semiconductor technology, our extensive pool of candidates brings expertise in diverse areas, including embedded systems, Citrix, routers, switches, firewalls, IPS, VMware, Solaris, and TCP/IP protocols. Furthermore, our candidates possess proficiency in databases such as Unix, Linux, and Sybase, ensuring comprehensive coverage of telecom requirements.</p>
                                        <p>Our services are tailored to empower global telecommunication companies with smarter processes and advanced analytics. Through our recruitment solutions, we provide high-quality manpower resources that drive intelligent decision-making, enhance operational performance, and foster exceptional customer satisfaction.</p>
                                        <p>Whether it's database management, application development, SOA solutions, or cloud services, our offerings optimize operational efficiency, maximize profit margins, and ensure excellence throughout the telecom process management.</p>
                                        <p>At Digital Spidy, we pride ourselves on our unwavering commitment to excellence. Our flexible engagement models, dedicated service delivery teams, and round-the-clock recruiting and service solutions ensure that our clients receive unparalleled support throughout their recruitment journey.</p>
                                        <p>Whether you require executive-level placements, contract staffing, or permanent placements, Digital Spidy is your trusted partner in navigating the dynamic landscape of telecommunications, media, and technology.</p>
                                        <p>With our tailored recruitment solutions, we guarantee time and cost savings, providing you with the talent you need precisely when, where, and how you need it.</p>
                                        <div className="ttm-horizontal_sep width-100 mt-25 mb-35"></div>
                                        
                                    </div>
                                </div>
                                {/* ttm-service-single-content-are end */}
                            </div>
                            <div className="col-lg-4 widget-area">
                                <div className="sidebar-right">
                                    <aside className="widget widget-nav-menu">
                                        <ul>
                                            <li><a href={process.env.PUBLIC_URL + '/Revolutionizing_real_estate_construction_talent_management'}>Revolutionizing Real Estate & Construction Talent Management</a></li>                                            
                                            <li><a href={process.env.PUBLIC_URL + '/Banking_finance_recruitment_solutions'}>Banking and Finance Recruitment Solutions</a></li>
                                            <li><a href={process.env.PUBLIC_URL + '/Automotive_industrial_engineering_recruitment_solutions'}>Automotive and Industrial Engineering Recruitment Solutions</a></li>
                                            <li><a href={process.env.PUBLIC_URL + '/Retail_e_commerce_recruitment_solutions'}>Retail & e-Commerce Recruitment Solutions</a></li>
                                            <li><a href={process.env.PUBLIC_URL + '/Clinical_pharmaceutical_industry_recruitment_solutions'}>Clinical & Pharmaceutical Industry Recruitment Solutions</a></li>
                                            <li><a href={process.env.PUBLIC_URL + '/Healthcare_insurance_recruitment_solutions'}>Healthcare & Insurance Recruitment Solutions</a></li>
                                            <li><a href={process.env.PUBLIC_URL + '/Energy_utilities_recruitment_solutions'}>Energy & Utilities Recruitment Solutions</a></li>
                                            <li><a href={process.env.PUBLIC_URL + '/Telecom_media_technology_recruitment_solutions'}>Telecom, Media & Technology Recruitment Solutions</a></li>
                                            <li><a href={process.env.PUBLIC_URL + '/Travel_hospitality_recruitment_solutions'}>Travel & Hospitality Recruitment Solutions</a></li>
                                        </ul>
                                    </aside>
                                    
                                    <aside className="widget widget-banner">
                                        <div className="ttm-col-bgcolor-yes bg-theme-DarkColor ttm-bg p-30 pb-50 pt-45">
                                            <div className="ttm-col-wrapper-bg-layer ttm-bg-layer bg-theme-DarkColor">
                                                <div className="ttm-col-wrapper-bg-layer-inner"></div>
                                            </div>
                                            <div className="layer-content text-center text-theme-WhiteColor">
                                            <div className="ttm-icon ttm-icon_element-onlytxt ttm-icon_element-style-round ttm-icon_element-color-skincolor ttm-icon_element-size-xl">
                                                <i className="far fa-comments"></i></div>
                                                <h3>Do You Need Any Help?</h3>
                                                <div className="ttm-horizontal_sep width-100 mt-25 mb-25"></div>
                                                <ul>
                                                    <li>+971-52 219-4019 </li>
                                                    <li>hr@digitalspidy.com</li>
                                                </ul>
                                                <a className="ttm-btn ttm-btn-size-md ttm-btn-shape-rounded ttm-btn-style-fill 
                                                ttm-btn-color-skincolor" href={process.env.PUBLIC_URL + '/contact'}>Appoinment</a>
                                            </div>
                                        </div>
                                    </aside>       
                                </div>
                            </div>
                        </div>{/* row end */}
                    </div>
                </div>

            <Footer/>
                        
            </div>
          )
      }
   }

export default Telecommunications_media_technology;