import React, { Component } from 'react';
import Header from '../components/layout/Header';
import PageHeader from "../components/layout/PageHeader";
import { Footer } from '../components/layout/Footer';

export class Clinical_pharmaceutical_industry_recruitment_solutions extends Component {
    render() {
        return (

            <div className="site-main">
                <Header/>
            
                {/* PageHeader */} 
                <PageHeader
                    title="Clinical & Pharmaceutical Industry Recruitment Solutions"
                    breadcrumb="Industries"
                />
                {/* PageHeader end */}  


                <div className="ttm-row sidebar ttm-sidebar clearfix">
                    <div className="container">
                        {/* row */}
                        <div className="row">
                            <div className="col-lg-8 content-area order-lg-2">
                                {/* ttm-service-single-content-area */}
                                <div className="ttm-service-single-content-area">
                                    <div className="ttm_single_image-wrapper">
                                        <img className="img-fluid" src="images/industries/pharmaceutical.jpg" alt="services-img" />
                                    </div>
                                    <div className="ttm-service-description mt-40">
                                        <h3 className="mb-20">Clinical & Pharmaceutical Industry Recruitment Solutions</h3>
                                        <p>Digital Spidy specializes in providing customized recruitment solutions for clients in the clinical and pharmaceutical sectors. Our focus is on empowering organizations to prioritize clinical delivery and development offerings while optimizing operations for cost reduction, enhanced customer satisfaction, and quality care delivery.</p>
                                        <p>Our comprehensive clinical and pharmaceutical solutions cover a wide range of specialized resources, including expertise in testing, release management, controlled release, inhalation, injectable profiles, analytical chemistry, clinical supply, biotech, pharmaceutical supply chain management, biologics, respiratory care, formulation development, drug delivery, clinical packaging, dose form manufacturing, and more. We prioritize talent with a focus on achieving better outcomes and increasing compliance, while emphasizing safety, convenience, and adherence.</p>
                                        <p>Digital Spidy provides a platform for clients to efficiently plan and manage their talent needs, offering scalable infrastructure and tailored talent services. Our fully automated manpower solutions ensure streamlined processes, enhancing efficiency and effectiveness.</p>
                                        <p>We specialize in domains critical to the clinical and pharmaceutical sectors, including pharma supply chain and formulation, clinical supply chain and formulation, biotechnology, and drug manufacturing.</p>
                                        <p>Partnering with Digital Spidy ensures executive-level commitment, a flexible engagement model, single-point-of-contact account management, client-dedicated service delivery teams, and 24x7 recruiting and service delivery. Our solution-driven approach drives efficiency and cost-effectiveness, providing clients with the best-fit talent on demand to meet their unique needs and objectives.</p>
                                        <p>Choose Digital Spidy for recruitment solutions that elevate your clinical and pharmaceutical operations. Contact us today to discover how we can help you secure the talent you need to succeed in your industry.</p>
                                        <div className="ttm-horizontal_sep width-100 mt-25 mb-35"></div>
                                        
                                    </div>
                                </div>
                                {/* ttm-service-single-content-are end */}
                            </div>
                            <div className="col-lg-4 widget-area">
                                <div className="sidebar-right">
                                    <aside className="widget widget-nav-menu">
                                        <ul>
                                            <li><a href={process.env.PUBLIC_URL + '/Revolutionizing_real_estate_construction_talent_management'}>Revolutionizing Real Estate & Construction Talent Management</a></li>                                            
                                            <li><a href={process.env.PUBLIC_URL + '/Banking_finance_recruitment_solutions'}>Banking and Finance Recruitment Solutions</a></li>
                                            <li><a href={process.env.PUBLIC_URL + '/Automotive_industrial_engineering_recruitment_solutions'}>Automotive and Industrial Engineering Recruitment Solutions</a></li>
                                            <li><a href={process.env.PUBLIC_URL + '/Retail_e_commerce_recruitment_solutions'}>Retail & e-Commerce Recruitment Solutions</a></li>
                                            <li><a href={process.env.PUBLIC_URL + '/Clinical_pharmaceutical_industry_recruitment_solutions'}>Clinical & Pharmaceutical Industry Recruitment Solutions</a></li>
                                            <li><a href={process.env.PUBLIC_URL + '/Healthcare_insurance_recruitment_solutions'}>Healthcare & Insurance Recruitment Solutions</a></li>
                                            <li><a href={process.env.PUBLIC_URL + '/Energy_utilities_recruitment_solutions'}>Energy & Utilities Recruitment Solutions</a></li>
                                            <li><a href={process.env.PUBLIC_URL + '/Telecom_media_technology_recruitment_solutions'}>Telecom, Media & Technology Recruitment Solutions</a></li>
                                            <li><a href={process.env.PUBLIC_URL + '/Travel_hospitality_recruitment_solutions'}>Travel & Hospitality Recruitment Solutions</a></li>
                                        </ul>
                                    </aside>
                                    
                                    <aside className="widget widget-banner">
                                        <div className="ttm-col-bgcolor-yes bg-theme-DarkColor ttm-bg p-30 pb-50 pt-45">
                                            <div className="ttm-col-wrapper-bg-layer ttm-bg-layer bg-theme-DarkColor">
                                                <div className="ttm-col-wrapper-bg-layer-inner"></div>
                                            </div>
                                            <div className="layer-content text-center text-theme-WhiteColor">
                                            <div className="ttm-icon ttm-icon_element-onlytxt ttm-icon_element-style-round ttm-icon_element-color-skincolor ttm-icon_element-size-xl">
                                                <i className="far fa-comments"></i></div>
                                                <h3>Do You Need Any Help?</h3>
                                                <div className="ttm-horizontal_sep width-100 mt-25 mb-25"></div>
                                                <ul>
                                                    <li>+971-52 219-4019 </li>
                                                    <li>hr@digitalspidy.com</li>
                                                </ul>
                                                <a className="ttm-btn ttm-btn-size-md ttm-btn-shape-rounded ttm-btn-style-fill 
                                                ttm-btn-color-skincolor" href={process.env.PUBLIC_URL + '/contact'}>Appoinment</a>
                                            </div>
                                        </div>
                                    </aside>       
                                </div>
                            </div>
                        </div>{/* row end */}
                    </div>
                </div>

            <Footer/>
                        
            </div>
          )
      }
   }

export default Clinical_pharmaceutical_industry_recruitment_solutions;