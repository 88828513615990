import React, { Component } from 'react';
import {BrowserRouter as Router, Route} from 'react-router-dom';
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import './css/vendor/flaticon.css';
import './css/vendor/fontawesome.min.css';
import './css/vendor/themify-icons.css';

import Home from './pages/Home';
import Home2 from './pages/Home2';
import Home3 from './pages/Home3';

import About_us from './pages/About_us';
import Services from './pages/Services';
import Services_details from './pages/Services_details';
import Consulting_digital_transformation from './pages/Consulting_digital_transformation';
import Elevate_your_workforce_with_next_level_recruitment_solutions from './pages/Elevate_your_workforce_with_next_level_recruitment_solutions';
import Streamlined_recruitment_solutions from './pages/Streamlined_recruitment_solutions';
import Dynamic_product_development_solutions from './pages/Dynamic_product_development_solutions';
import Seamless_immigration_assistance from './pages/Seamless_immigration_assistance';
import Career_guidance from './pages/Career_guidance';
import Revolutionizing_real_estate_construction_talent_management from './pages/Revolutionizing_real_estate_construction_talent_management';
import Banking_finance_recruitment_solutions from './pages/Banking_finance_recruitment_solutions';
import Automotive_industrial_engineering_recruitment_solutions from './pages/Automotive_industrial_engineering_recruitment_solutions';
import Retail_e_commerce_recruitment_solutions from './pages/Retail_e_commerce_recruitment_solutions';
import Clinical_pharmaceutical_industry_recruitment_solutions from './pages/Clinical_pharmaceutical_industry_recruitment_solutions';
import Healthcare_insurance_recruitment_solutions from './pages/Healthcare_insurance_recruitment_solutions';
import Energy_utilities_recruitment_solutions from './pages/Energy_utilities_recruitment_solutions';
import Telecom_media_technology_recruitment_solutions from './pages/Telecom_media_technology_recruitment_solutions';
import Travel_hospitality_recruitment_solutions from './pages/Travel_hospitality_recruitment_solutions';
import Blog_classic from './pages/Blog_classic';
import Blog_grid from './pages/Blog_grid';
import Blog_details from './pages/Blog_details';
import Contact from './pages/Contact';
import Contact_02 from './pages/Contact_02';
import Job_list from './pages/Job_list';
import Job_details from './pages/Job_details';
import Carrer_01 from './pages/Carrer_01';
import Error_404 from './pages/Error_404';
import Error_405 from './pages/Error_405';
import Employers_list from './pages/Employers_list';
import Employers_details from './pages/Employers_details';
import Candidate_list from './pages/Candidate_list';
import Candidate_details from './pages/Candidate_details';
import Login from './pages/Login';
import Register from './pages/Register';


import ScrollToTop from './components/layout/Gotop';


function App() {
 
  return (
    <div className="page">
      <Router> 
        
        <Route exact path={`${process.env.PUBLIC_URL + '/'}`} component={ Home } />
        <Route exact path={`${process.env.PUBLIC_URL + '/Home2'}`} component={ Home2 } /> 
        <Route exact path={`${process.env.PUBLIC_URL + '/Home3'}`} component={ Home3 } /> 
        <Route exact path={`${process.env.PUBLIC_URL + '/About_us'}`} component={ About_us } /> 
        <Route exact path={`${process.env.PUBLIC_URL + '/Services'}`} component={ Services } />
        <Route exact path={`${process.env.PUBLIC_URL + '/Services_details'}`} component={ Services_details } />
        <Route exact path={`${process.env.PUBLIC_URL + '/Consulting_digital_transformation'}`} component={ Consulting_digital_transformation } />
        <Route exact path={`${process.env.PUBLIC_URL + '/Elevate_your_workforce_with_next_level_recruitment_solutions'}`} component={ Elevate_your_workforce_with_next_level_recruitment_solutions } />
        <Route exact path={`${process.env.PUBLIC_URL + '/Streamlined_recruitment_solutions'}`} component={ Streamlined_recruitment_solutions } />
        <Route exact path={`${process.env.PUBLIC_URL + '/Dynamic_product_development_solutions'}`} component={ Dynamic_product_development_solutions } />
        <Route exact path={`${process.env.PUBLIC_URL + '/Seamless_immigration_assistance'}`} component={ Seamless_immigration_assistance } />
        <Route exact path={`${process.env.PUBLIC_URL + '/Career_guidance'}`} component={ Career_guidance } />
        <Route exact path={`${process.env.PUBLIC_URL + '/Revolutionizing_real_estate_construction_talent_management'}`} component={ Revolutionizing_real_estate_construction_talent_management } />
        <Route exact path={`${process.env.PUBLIC_URL + '/Banking_finance_recruitment_solutions'}`} component={ Banking_finance_recruitment_solutions } />
        <Route exact path={`${process.env.PUBLIC_URL + '/Automotive_industrial_engineering_recruitment_solutions'}`} component={ Automotive_industrial_engineering_recruitment_solutions } />
        <Route exact path={`${process.env.PUBLIC_URL + '/Retail_e_commerce_recruitment_solutions'}`} component={ Retail_e_commerce_recruitment_solutions } />
        <Route exact path={`${process.env.PUBLIC_URL + '/Clinical_pharmaceutical_industry_recruitment_solutions'}`} component={ Clinical_pharmaceutical_industry_recruitment_solutions } />
        <Route exact path={`${process.env.PUBLIC_URL + '/Healthcare_insurance_recruitment_solutions'}`} component={ Healthcare_insurance_recruitment_solutions } />
        <Route exact path={`${process.env.PUBLIC_URL + '/Energy_utilities_recruitment_solutions'}`} component={ Energy_utilities_recruitment_solutions } />
        <Route exact path={`${process.env.PUBLIC_URL + '/Telecom_media_technology_recruitment_solutions'}`} component={ Telecom_media_technology_recruitment_solutions } />
        <Route exact path={`${process.env.PUBLIC_URL + '/Travel_hospitality_recruitment_solutions'}`} component={ Travel_hospitality_recruitment_solutions } />
        <Route exact path={`${process.env.PUBLIC_URL + '/Blog_classic'}`} component={ Blog_classic } />
        <Route exact path={`${process.env.PUBLIC_URL + '/Blog_grid'}`} component={ Blog_grid } />
        <Route exact path={`${process.env.PUBLIC_URL + '/Blog_details'}`} component={ Blog_details } />
        <Route exact path={`${process.env.PUBLIC_URL + '/Contact'}`} component={ Contact } />
        <Route exact path={`${process.env.PUBLIC_URL + '/Contact_02'}`} component={ Contact_02 } />
        <Route exact path={`${process.env.PUBLIC_URL + '/Job_list'}`} component={ Job_list } />
        <Route exact path={`${process.env.PUBLIC_URL + '/Job_details'}`} component={ Job_details } />
        <Route exact path={`${process.env.PUBLIC_URL + '/Carrer_01'}`} component={ Carrer_01 } />
        <Route exact path={`${process.env.PUBLIC_URL + '/Error_404'}`} component={ Error_404 } />
        <Route exact path={`${process.env.PUBLIC_URL + '/Error_405'}`} component={ Error_405 } />
        <Route exact path={`${process.env.PUBLIC_URL + '/Employers_list'}`} component={ Employers_list } />
        <Route exact path={`${process.env.PUBLIC_URL + '/Employers_details'}`} component={ Employers_details } />
        <Route exact path={`${process.env.PUBLIC_URL + '/Candidate_list'}`} component={ Candidate_list } />
        <Route exact path={`${process.env.PUBLIC_URL + '/Candidate_details'}`} component={ Candidate_details } />
        <Route exact path={`${process.env.PUBLIC_URL + '/Login'}`} component={ Login } />
        <Route exact path={`${process.env.PUBLIC_URL + '/Register'}`} component={ Register } />
       

        <ScrollToTop />
      </Router>
    </div>
  );
}

export default App;


