import React, { Component } from 'react';
import Header from '../components/layout/Header';
import PageHeader from "../components/layout/PageHeader";
import { Footer } from '../components/layout/Footer';

export class Revolutionizing_real_estate_construction_talent_management extends Component {
    render() {
        return (

            <div className="site-main">
                <Header/>
            
                {/* PageHeader */} 
                <PageHeader
                    title="Revolutionizing Real Estate & Construction Talent Management"
                    breadcrumb="Industries"
                />
                {/* PageHeader end */}  


                <div className="ttm-row sidebar ttm-sidebar clearfix">
                    <div className="container">
                        {/* row */}
                        <div className="row">
                            <div className="col-lg-8 content-area order-lg-2">
                                {/* ttm-service-single-content-area */}
                                <div className="ttm-service-single-content-area">
                                    <div className="ttm_single_image-wrapper">
                                        <img className="img-fluid" src="images/industries/realestate.jpg" alt="services-img" />
                                    </div>
                                    <div className="ttm-service-description mt-40">
                                        <h3 className="mb-20">Revolutionizing Real Estate & Construction Talent Management</h3>
                                        <p>At Digital Spidy, we specialize in meeting the distinct requirements of the real estate and construction industries, covering a broad spectrum of sectors involved in property development and building endeavors.</p>
                                        <p><b>Construction: </b>Our expertise spans from construction to renovation, encompassing residential and commercial projects, including bridges, roadways, and extensive painting tasks. Whether it involves erecting buildings, paving roads, or demolishing structures, we are equipped to handle it all.</p>
                                        <p><b>Residential Construction: </b>From adding new rooms to renovating kitchens, our focus lies in enhancing residences to align with evolving homeowner needs.</p>
                                        <p><b>Commercial Construction:</b>We tackle diverse projects with precision and proficiency, ranging from apartments to office complexes, schools to industrial buildings.</p>
                                        <p><b>Real Estate:</b>Our services extend across all facets of real estate, from property buying and selling to renting. With specialized skills in appraisals and property management, we cater to residential, commercial, and industrial sectors.</p>
                                        <h4>Industry-specific Talent Solutions</h4>
                                        <p>We provide a comprehensive platform for talent planning and management, offering scalable infrastructure and customized talent services tailored to our clients' business requirements. Our pool of resources includes individuals proficient in construction sites, zoning, civil engineering, and industrial mechanisms, among others.</p>
                                        <p><b>Our focus areas include:</b></p>
                                        <ul>
                                            <li>Commercial Real Estate and Construction</li>
                                            <li>Residential Real Estate and Construction</li>
                                            <li>Industrial Real Estate and Construction</li>
                                        </ul>
                                        <p><b>Our Service Offerings:</b></p>
                                        <ul>
                                            <li>Executive-level commitment</li>
                                            <li>Flexible engagement models</li>
                                            <li>Dedicated account management</li>
                                            <li>Client-specific service delivery teams</li>
                                            <li>24x7 recruiting and service delivery</li>
                                            <li>Direct hire, contract, contract-to-hire, permanent placement, and executive search services</li>
                                        </ul>
                                        <br></br>
                                        <p>Our service model ensures timely and cost-effective talent acquisition, providing you with the best-fit candidates precisely when and where you need them. Experience streamlined processes, cost savings, and access to top talent with Digital Spidy.</p>
                                        <p>Unlock your potential with our innovative talent solutions. Contact us today to discover how we can elevate your business.</p>
                                        <div className="ttm-horizontal_sep width-100 mt-25 mb-35"></div>
                                        
                                    </div>
                                </div>
                                {/* ttm-service-single-content-are end */}
                            </div>
                            <div className="col-lg-4 widget-area">
                                <div className="sidebar-right">
                                    <aside className="widget widget-nav-menu">
                                        <ul>
                                            <li><a href={process.env.PUBLIC_URL + '/Revolutionizing_real_estate_construction_talent_management'}>Revolutionizing Real Estate & Construction Talent Management</a></li>                                            
                                            <li><a href={process.env.PUBLIC_URL + '/Banking_finance_recruitment_solutions'}>Banking and Finance Recruitment Solutions</a></li>
                                            <li><a href={process.env.PUBLIC_URL + '/Automotive_industrial_engineering_recruitment_solutions'}>Automotive and Industrial Engineering Recruitment Solutions</a></li>
                                            <li><a href={process.env.PUBLIC_URL + '/Retail_e_commerce_recruitment_solutions'}>Retail & e-Commerce Recruitment Solutions</a></li>
                                            <li><a href={process.env.PUBLIC_URL + '/Clinical_pharmaceutical_industry_recruitment_solutions'}>Clinical & Pharmaceutical Industry Recruitment Solutions</a></li>
                                            <li><a href={process.env.PUBLIC_URL + '/Healthcare_insurance_recruitment_solutions'}>Healthcare & Insurance Recruitment Solutions</a></li>
                                            <li><a href={process.env.PUBLIC_URL + '/Energy_utilities_recruitment_solutions'}>Energy & Utilities Recruitment Solutions</a></li>
                                            <li><a href={process.env.PUBLIC_URL + '/Telecom_media_technology_recruitment_solutions'}>Telecom, Media & Technology Recruitment Solutions</a></li>
                                            <li><a href={process.env.PUBLIC_URL + '/Travel_hospitality_recruitment_solutions'}>Travel & Hospitality Recruitment Solutions</a></li>
                                        </ul>
                                    </aside>
                                    
                                    <aside className="widget widget-banner">
                                        <div className="ttm-col-bgcolor-yes bg-theme-DarkColor ttm-bg p-30 pb-50 pt-45">
                                            <div className="ttm-col-wrapper-bg-layer ttm-bg-layer bg-theme-DarkColor">
                                                <div className="ttm-col-wrapper-bg-layer-inner"></div>
                                            </div>
                                            <div className="layer-content text-center text-theme-WhiteColor">
                                            <div className="ttm-icon ttm-icon_element-onlytxt ttm-icon_element-style-round ttm-icon_element-color-skincolor ttm-icon_element-size-xl">
                                                <i className="far fa-comments"></i></div>
                                                <h3>Do You Need Any Help?</h3>
                                                <div className="ttm-horizontal_sep width-100 mt-25 mb-25"></div>
                                                <ul>
                                                    <li>+971-52 219-4019 </li>
                                                    <li>hr@digitalspidy.com</li>
                                                </ul>
                                                <a className="ttm-btn ttm-btn-size-md ttm-btn-shape-rounded ttm-btn-style-fill 
                                                ttm-btn-color-skincolor" href={process.env.PUBLIC_URL + '/contact'}>Appoinment</a>
                                            </div>
                                        </div>
                                    </aside>       
                                </div>
                            </div>
                        </div>{/* row end */}
                    </div>
                </div>

            <Footer/>
                        
            </div>
          )
      }
   }

export default Revolutionizing_real_estate_construction_talent_management;