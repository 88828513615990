import React, { Component } from 'react';
import Header from '../components/layout/Header';
import PageHeader from "../components/layout/PageHeader";
import { Footer } from '../components/layout/Footer';

export class Elevate_your_workforce_with_next_level_recruitment_solutions extends Component {
    render() {
        return (

            <div className="site-main">
                <Header/>
            
                {/* PageHeader */} 
                <PageHeader
                    title="Elevate Your Workforce with Next-Level Recruitment Solutions"
                    breadcrumb="Services"
                />
                {/* PageHeader end */}  


                <div className="ttm-row sidebar ttm-sidebar clearfix">
                    <div className="container">
                        {/* row */}
                        <div className="row">
                            <div className="col-lg-8 content-area order-lg-2">
                                {/* ttm-service-single-content-area */}
                                <div className="ttm-service-single-content-area">
                                    <div className="ttm_single_image-wrapper">
                                        <img className="img-fluid" src="images/services/engineering_technology.jpg" alt="services-img" />
                                    </div>
                                    <div className="ttm-service-description mt-40">
                                        <h3 className="mb-20">Elevate Your Workforce with Next-Level Recruitment Solutions</h3>
                                        <p>In the dynamic landscape of modern business, securing the right talent is paramount for success. At Digital Spidy, we specialize in comprehensive recruitment solutions tailored to the needs of both IT and non-IT enterprises.</p>
                                        <p>Our approach is strategic and results-driven. Recognizing the imperative of adapting to evolving market dynamics, we harness cutting-edge technology and industry insights to seamlessly connect businesses with top-tier professionals.</p>
                                        <p>From agile engineering to innovative hiring methodologies, we streamline the recruitment journey, ensuring our clients access the talent necessary to fuel growth and foster innovation. Backed by our customized solutions and dedicated team, we simplify the process of finding the perfect match. Partner with us to cultivate a workforce poised for future challenges.</p>
                                        <p>Reach out today to discover how Digital Spidy can elevate your recruitment endeavors to new heights.</p>
                                        <div className="ttm-horizontal_sep width-100 mt-25 mb-35"></div>
                                        
                                    </div>
                                </div>
                                {/* ttm-service-single-content-are end */}
                            </div>
                            <div className="col-lg-4 widget-area">
                                <div className="sidebar-right">
                                    <aside className="widget widget-nav-menu">
                                    <ul>
                                            <li><a className="active" href={process.env.PUBLIC_URL + '/Consulting_digital_transformation'}>Crafting Success: Consulting & Digital Transformation</a></li>
                                            <li><a href={process.env.PUBLIC_URL + '/Elevate_your_workforce_with_next_level_recruitment_solutions'}>Elevate Your Workforce with Next-Level Recruitment Solutions</a></li>
                                            <li><a href={process.env.PUBLIC_URL + '/Streamlined_recruitment_solutions'}>Streamlined Recruitment Solutions</a></li>
                                            <li><a href={process.env.PUBLIC_URL + '/Dynamic_product_development_solutions'}>Dynamic Product Development Solutions</a></li>
                                            <li><a href={process.env.PUBLIC_URL + '/Seamless_immigration_assistance'}>Seamless Immigration Assistance</a></li>
                                            <li><a href={process.env.PUBLIC_URL + '/Career_guidance'}>Career Guidance</a></li>
                                        </ul>
                                    </aside>
                                    
                                    <aside className="widget widget-banner">
                                        <div className="ttm-col-bgcolor-yes bg-theme-DarkColor ttm-bg p-30 pb-50 pt-45">
                                            <div className="ttm-col-wrapper-bg-layer ttm-bg-layer bg-theme-DarkColor">
                                                <div className="ttm-col-wrapper-bg-layer-inner"></div>
                                            </div>
                                            <div className="layer-content text-center text-theme-WhiteColor">
                                            <div className="ttm-icon ttm-icon_element-onlytxt ttm-icon_element-style-round ttm-icon_element-color-skincolor ttm-icon_element-size-xl">
                                                <i className="far fa-comments"></i></div>
                                                <h3>Do You Need Any Help?</h3>
                                                <div className="ttm-horizontal_sep width-100 mt-25 mb-25"></div>
                                                <ul>
                                                    <li>+971-52 219-4019 </li>
                                                    <li>hr@digitalspidy.com</li>
                                                </ul>
                                                <a className="ttm-btn ttm-btn-size-md ttm-btn-shape-rounded ttm-btn-style-fill 
                                                ttm-btn-color-skincolor" href={process.env.PUBLIC_URL + '/contact'}>Appoinment</a>
                                            </div>
                                        </div>
                                    </aside>       
                                </div>
                            </div>
                        </div>{/* row end */}
                    </div>
                </div>

            <Footer/>
                        
            </div>
          )
      }
   }

export default Elevate_your_workforce_with_next_level_recruitment_solutions;