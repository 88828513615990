import React, { Component } from 'react';
import Slider from 'react-slick';
import ProgressBar from 'react-animated-progress-bar';
import Header from '../components/layout/Header';
import PageHeader from "../components/layout/PageHeader";
import { Footer } from '../components/layout/Footer';
import { Tab, Tabs, TabList, TabPanel } from 'react-tabs';
import CountUp from 'react-countup';
import Video from '../components/layout/Video';


export class About_us extends Component {
  constructor(props) {
    super(props);
 
    this.state = {
      photoIndex: 0,
      isOpen: false,
    };
  }
    render() {
      var slick_slider = {
        dots: false,
        arrow: false,
        autoplay: true,
        infinite: true,
        speed: 1000,
        slidesToShow: 4,
        slidesToScroll: 1,
        rows: 1,

        responsive: [ {

            breakpoint: 1024,
            settings: {
                slidesToShow: 3,
                slidesToScroll: 3
            }
        },{
    
          breakpoint: 778,
          settings: {
              slidesToShow: 2,
              slidesToScroll: 2
          }
      },
      {
          breakpoint: 575,
          settings: {
              slidesToShow: 1,
              slidesToScroll: 1
          }
      }]
      };
        
        return (       
          <div className="site-main">

            <Header/>
            
            {/* PageHeader */} 
            <PageHeader
                title="about us"
                breadcrumb="about"
            />
            {/* PageHeader end */} 

            
            {/* about-section */}
            <section className="ttm-row about-section clearfix">
                <div className="container">
                    {/* row */}
                    <div className="row">
                        <div className="col-lg-6">
                            {/* section title */}
                            <div className="section-title">
                                <div className="title-header">
                                    <h3>Great <span className="text-theme-SkinColor">Search! </span></h3>
                                    <h2 className="title">Digital Spidy - Your Trusted Recruitment Partner</h2>
                                </div>
                                <div className="title-desc">
                                    <p>At Digital Spidy, we provide comprehensive end-to-end recruitment solutions for both the IT and Non-IT sectors.</p>
                                    
                                </div>
                                <uL>
                                        <li>Top-notch recruitment services.</li>
                                        <li>Trusted since 2016.</li>
                                        <li>Presence in Dubai, Canada, and India.</li>
                                        <li>Globally competitive among the top recruiting firms in India.</li>
                                    </uL>
                            </div>{/* section title end */}

                            <div className="ttm-tabs ttm-tab-style-01">
                                <Tabs>
                                    <TabList className="tabs">
                                        <Tab className="tab"><a className="tab-1" tabIndex="0">Vision</a></Tab>
                                        <Tab className="tab"><a className="tab-2" tabIndex="0">Mission</a></Tab>
                                        <Tab className="tab"><a className="tab-3" tabIndex="0">Values</a></Tab>
                                        <Tab className="tab"><a className="tab-4" tabIndex="0">Work Culture</a></Tab>
                                        
                                    </TabList> 
                                    <div className="content-tab">                                
                                        <TabPanel>
                                        <div className="row">
                                            <div className="col-lg-4 col-md-3 col-sm-4">
                                                <img className="img-fluid alignleft" height="200" width="200" src="images/tab-img02.jpg" alt="image" />
                                            </div>
                                            <div className="col-lg-8 col-md-9 col-sm-8">
                                                <div className="pt-15">
                                                    <p>Our vision at Digital Spidy is crystal clear: By prioritizing quality and ethics, we aim to rank among the top 25 staffing agencies by 2025.</p>
                                                    
                                                </div>
                                            </div>
                                        </div>
                                        </TabPanel>
                                        <TabPanel>
                                        <div className="row">
                                            <div className="col-lg-4 col-md-3 col-sm-4">
                                                <img className="img-fluid alignleft" height="200" width="200" src="images/tab-img01.jpg" alt="image" />
                                            </div>
                                            <div className="col-lg-8 col-md-9 col-sm-8">
                                                <div className="pt-15">
                                                    <p>Our mission is to institutionalize recruitment process standards through innovative strategies and collaborative teamwork.</p>
                                                   
                                                </div>
                                            </div>
                                        </div>
                                        </TabPanel>
                                        <TabPanel>
                                        <div className="row">
                                            <div className="col-lg-4 col-md-3 col-sm-4">
                                                <img className="img-fluid alignleft" height="200" width="200" src="images/tab-img03.jpg" alt="image" />
                                            </div>
                                            <div className="col-lg-8 col-md-9 col-sm-8">
                                                <div className="pt-15">
                                                    <p>At Digital Spidy, we cherish our values, which include prioritizing customer experience and care, ensuring employee satisfaction, fostering training and development, upholding health and safety standards, contributing to stakeholder success, and achieving collective triumph.</p>
                                                    
                                                </div>
                                            </div>
                                        </div>
                                        </TabPanel>
                                        <TabPanel>
                                        <div className="row">
                                            <div className="col-lg-4 col-md-3 col-sm-4">
                                                <img className="img-fluid alignleft" height="200" width="200" src="images/tab-img03.jpg" alt="image" />
                                            </div>
                                            <div className="col-lg-8 col-md-9 col-sm-8">
                                                <div className="pt-15">
                                                    <p>Our work culture is characterized by fostering healthy work relationships, driving increased productivity, maintaining a gender equality ratio, and promoting a strong sense of safety and well-being.</p>
                                                    
                                                </div>
                                            </div>
                                        </div>
                                        </TabPanel>
                                    </div>       
                                </Tabs>
                            </div>

                        </div>
                        <div className="col-lg-6">
                            <div className="mr-30 mt-30 mt-lg-60 mb-lg-30">
                                <div className="d-flex">
                                    <img src="images/about.jpg" className="img-fluid" alt="single_05" />
                                    <div className="flex-basis-auto ml_180 mr_30 mt_30 z-index_1">
                                        <img src="images/dot-pattern.png" className="img-fluid" alt="dot-pattern" />
                                    </div>
                                    <div className="d-flex justify-content-end flex-column ml_180 mr_30 mb_35 z-index_1">
                                        <img src="images/dot-pattern.png" className="img-fluid" alt="dot-pattern" />
                                    </div>
                                </div>
                            </div>  
                        </div>
                    </div>{/* row end */}
                    <div className="row">
                        <div className="col-lg-12">
                            <h4>How We Stand Out</h4>
                            <p>Digital Spidy sets itself apart through its unwavering commitment to environmental sustainability, training, health, and safety standards. Additionally, we prioritize recruitment for differently-abled individuals, embrace corporate social responsibility, adhere to legal regulations, and provide ample learning and development opportunities.</p>
                            <p>Choose Digital Spidy as your recruitment partner and witness the difference. Contact us today to discover how we can assist you in finding the best talent for your organization.</p>
                        </div>
                    </div>
                </div>
            </section>
            {/* about-section end */}



            {/* google_map */}
            <div id="google_map" className="google_map">
                <div className="map_container clearfix">
                    <div id="map">{/*
                    <iframe src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d20413.694868665534!2d55.29925512357566!3d25.248188471227866!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x3e5f432a3b2b3c25%3A0x1f2d9591f4bf27cb!2sMontana%20Building%20Al%20Waleed%20Real%20Estate!5e0!3m2!1sen!2sin!4v1708198314003!5m2!1sen!2sin" width="100%" height="450"></iframe>
                    */}
                    <iframe src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d115476.54802888783!2d55.15363619726562!3d25.248976000000006!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x3e5f43d85189d2e1%3A0x4666fd0cd53372f1!2sDigital%20Spidy%20Hr%20Consultancies%20LLC!5e0!3m2!1sen!2sin!4v1710829405103!5m2!1sen!2sin" width="100%" height="450"></iframe>
                    </div>
                </div>
            </div>
            {/* google_map end */}
            

            {/* action-section */}
            <section className="ttm-row action-section bg-theme-SkinColor text-theme-WhiteColor clearfix">
                <div className="container">
                    <div className="row">
                        <div className="col-lg-12">
                            <div className="d-md-flex align-items-center justify-content-between">
                                {/* featured-icon-box */}
                                <div className="featured-icon-box icon-align-before-content style2">
                                    <div className="featured-icon">
                                        <div className="ttm-icon ttm-icon_element-onlytxt ttm-icon_element-color-white ttm-icon_element-size-xl">
                                            <i className="flaticon flaticon-recruitment-5"></i>
                                        </div>
                                    </div>
                                    <div className="featured-content">
                                        <div className="featured-title">
                                            <h3>Incredible Recruitment & Staffing Agency</h3>
                                        </div>
                                        <div className="featured-desc">
                                            <p>Our dedicated team is committed to providing personalized solutions tailored to your organizational goals.</p>
                                        </div>
                                    </div>
                                </div>{/* featured-icon-box end */}
                                <a className="ttm-btn ttm-btn-size-md ttm-btn-shape-rounded ttm-btn-style-border ttm-btn-color-white" 
                                href={process.env.PUBLIC_URL + '/contact'}>Hiring Now!</a>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
            {/* action-section end */}


            <Footer/>
                        
         </div>
       )
   }
}


export default About_us;