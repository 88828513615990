import React, { Component } from 'react';
import Header from '../components/layout/Header';
import PageHeader from "../components/layout/PageHeader";
import { Footer } from '../components/layout/Footer';

export class Streamlined_recruitment_solutions extends Component {
    render() {
        return (

            <div className="site-main">
                <Header/>
            
                {/* PageHeader */} 
                <PageHeader
                    title="Streamlined Recruitment Solutions"
                    breadcrumb="Services"
                />
                {/* PageHeader end */}  


                <div className="ttm-row sidebar ttm-sidebar clearfix">
                    <div className="container">
                        {/* row */}
                        <div className="row">
                            <div className="col-lg-8 content-area order-lg-2">
                                {/* ttm-service-single-content-area */}
                                <div className="ttm-service-single-content-area">
                                    <div className="ttm_single_image-wrapper">
                                        <img className="img-fluid" src="images/services/recruitment.jpg" alt="services-img" />
                                    </div>
                                    <div className="ttm-service-description mt-40">
                                        <h3 className="mb-20">Streamlined Recruitment Solutions</h3>
                                        <p>At Digital Spidy, we offer a comprehensive range of recruitment solutions customized to meet the specific requirements of both IT and non-IT enterprises.</p>
                                        <h4>Permanent Hiring</h4>
                                        <p>We specialize in sourcing and recruiting full-time personnel for our clients, providing transparent fixed-percentage pricing with a dedicated focus on delivering measurable outcomes.</p>
                                        <h4>Temporary Hiring</h4>
                                        <p>For short-term staffing needs, we facilitate connections with contractors through flexible Contract-to-Term or Contract-to-Hire arrangements. Our pricing structure is adaptable, based on either a Cost Model or Markup Model agreed upon in the contract.</p>
                                        <h4>BPO/Call Center Recruitment</h4>
                                        <p>Our emphasis on service excellence drives our recruitment efforts for BPO and call center positions under service contracts. We are committed to achieving performance targets within SLAs, catering to the requirements of both end users and business users.</p>
                                        <h4>RPO</h4>
                                        <p>Through Recruitment Process Outsourcing (RPO), we offer dedicated implant support to clients. Our pricing includes a success fee and implant cost, ensuring a seamless recruitment process.</p>
                                        <h4>Fresher Hiring</h4>
                                        <p>We facilitate fresher recruitment for clients through structured campus drives, offering a transparent fixed-cost model for straightforward hiring processes.</p>
                                        <h4>International Recruitment</h4>
                                        <p>In addition to IT roles such as Software Engineers and Data Scientists, we provide staff augmentation services for various non-IT positions globally. From mechanics to nursing staff, our recruitment solutions span diverse industries and job roles.</p>
                                        <p>Partner with Digital Spidy for efficient and effective recruitment solutions tailored to your organization's needs. Contact us today to discover how we can support your talent acquisition efforts and assist you in building a skilled and dynamic workforce.</p>
                                        <div className="ttm-horizontal_sep width-100 mt-25 mb-35"></div>
                                        
                                    </div>
                                </div>
                                {/* ttm-service-single-content-are end */}
                            </div>
                            <div className="col-lg-4 widget-area">
                                <div className="sidebar-right">
                                    <aside className="widget widget-nav-menu">
                                    <ul>
                                            <li><a className="active" href={process.env.PUBLIC_URL + '/Consulting_digital_transformation'}>Crafting Success: Consulting & Digital Transformation</a></li>
                                            <li><a href={process.env.PUBLIC_URL + '/Elevate_your_workforce_with_next_level_recruitment_solutions'}>Elevate Your Workforce with Next-Level Recruitment Solutions</a></li>
                                            <li><a href={process.env.PUBLIC_URL + '/Streamlined_recruitment_solutions'}>Streamlined Recruitment Solutions</a></li>
                                            <li><a href={process.env.PUBLIC_URL + '/Dynamic_product_development_solutions'}>Dynamic Product Development Solutions</a></li>
                                            <li><a href={process.env.PUBLIC_URL + '/Seamless_immigration_assistance'}>Seamless Immigration Assistance</a></li>
                                            <li><a href={process.env.PUBLIC_URL + '/Career_guidance'}>Career Guidance</a></li>
                                        </ul>
                                    </aside>
                                    
                                    <aside className="widget widget-banner">
                                        <div className="ttm-col-bgcolor-yes bg-theme-DarkColor ttm-bg p-30 pb-50 pt-45">
                                            <div className="ttm-col-wrapper-bg-layer ttm-bg-layer bg-theme-DarkColor">
                                                <div className="ttm-col-wrapper-bg-layer-inner"></div>
                                            </div>
                                            <div className="layer-content text-center text-theme-WhiteColor">
                                            <div className="ttm-icon ttm-icon_element-onlytxt ttm-icon_element-style-round ttm-icon_element-color-skincolor ttm-icon_element-size-xl">
                                                <i className="far fa-comments"></i></div>
                                                <h3>Do You Need Any Help?</h3>
                                                <div className="ttm-horizontal_sep width-100 mt-25 mb-25"></div>
                                                <ul>
                                                    <li>+971-52 219-4019 </li>
                                                    <li>hr@digitalspidy.com</li>
                                                </ul>
                                                <a className="ttm-btn ttm-btn-size-md ttm-btn-shape-rounded ttm-btn-style-fill 
                                                ttm-btn-color-skincolor" href={process.env.PUBLIC_URL + '/contact'}>Appoinment</a>
                                            </div>
                                        </div>
                                    </aside>       
                                </div>
                            </div>
                        </div>{/* row end */}
                    </div>
                </div>

            <Footer/>
                        
            </div>
          )
      }
   }

export default Streamlined_recruitment_solutions;