import React, { Component } from 'react'

export class Footer extends Component {
    render() {
        return (
            <footer className="footer widget-footer bg-theme-DarkColor text-theme-WhiteColor clearfix">
                <div className="second-footer">
                    <div className="container">
                        <div className="row">
                            <div className="col-xs-12 col-sm-12 col-md-12 col-lg-4 widget-area">
                                <div className="widget widget_text clearfix">
                                    <div className="footer-logo">
                                        <img id="footer-logo-img" className="img-fluid auto_size" height="40" width="162" src="images/logo.png" alt="footer-logo" />
                                    </div>
                                    <p>Digital Spidy – your gateway to elevated excellence in recruitment services. As a trusted industry leader, Digital Spidy specializes in connecting businesses with exceptional professionals, navigating the recruitment landscape with precision.</p>
                                    <div className="d-flex align-items-center pt-15 pb-25">
                                        <h6 className="fw-normal">Social Share: </h6>
                                        <div className="social-icons">
                                            <ul className="social-icons list-inline">
                                                <li><a href="https://www.facebook.com/DigitalSpidySolutions/" rel="noopener" aria-label="facebook">
                                                    <i className="ti ti-facebook"></i></a>
                                                </li>
                                                <li><a href="https://twitter.com/Digitalspidy/" rel="noopener" aria-label="twitter">
                                                    <i className="ti ti-twitter-alt"></i></a>
                                                </li>
                                                
                                                <li><a href="https://www.pinterest.ca/digitalspidy/" rel="noopener" aria-label="pinterest">
                                                    <i className="ti ti-pinterest"></i></a>
                                                </li>
                                            </ul>
                                        </div>
                                    </div>
                                    <a className="ttm-btn ttm-btn-size-md ttm-btn-shape-rounded ttm-btn-style-border ttm-btn-color-white" 
                                    href={process.env.PUBLIC_URL + '/contact'}>Request A Free Quote</a>
                                </div>
                            </div>
                            <div className="col-xs-12 col-sm-6 col-md-6 col-lg-4 widget-area">
                                <div className="widget widget-recent-post clearfix">
                                        <h3 className="widget-title">Our Services</h3>
                                        <ul className="mega-submenu">
                                            <li><a href={process.env.PUBLIC_URL + '/real_estate_construction'}>Real Estate & Construction</a></li>
                                            <li><a href={process.env.PUBLIC_URL + '/banking_finance'}>Banking and Finance</a></li>
                                            <li><a href={process.env.PUBLIC_URL + '/automotive_industrial_engineering'}>Automotive and Industrial Engineering</a></li>
                                            <li><a href={process.env.PUBLIC_URL + '/retail_e-commerce'}>Retail & e-Commerce</a></li>
                                            <li><a href={process.env.PUBLIC_URL + '/pharmaceutical'}>Pharmaceutical</a></li>
                                            <li><a href={process.env.PUBLIC_URL + '/healthcare_insurance'}>Healthcare & Insurance</a></li>
                                            <li><a href={process.env.PUBLIC_URL + '/energy_utilities'}>Energy & Utilities</a></li>
                                            <li><a href={process.env.PUBLIC_URL + '/telecommunications_media_technology'}>Telecommunications, Media & Technology</a></li>
                                            <li><a href={process.env.PUBLIC_URL + '/travel_hospitality'}>Travel & Hospitality</a></li>
                                        </ul>
                                </div>
                            </div>
                            <div className="col-xs-12 col-sm-6 col-md-6 col-lg-4 widget-area">
                                <div className="widget widget-contact clearfix">
                                    <h3 className="widget-title">Quick Contact!</h3>
                                    <ul className="widget_contact_wrapper">
                                        <li>
                                            <i className="ttm-textcolor-skincolor flaticon-phone-call"></i>
                                            <h3>Call Us On:</h3>+971-52 219-4019 <br></br>+1 (647) 877-4944</li>
                                        <li>
                                            <i className="ttm-textcolor-skincolor flaticon-placeholder"></i>
                                            <h3>Address:</h3>Montana Building #102-033, AI Karama, P.O Box 44320, Dubai.<br></br><br></br>
                                            70 Seward Crescent Ajax, Ontario, Canada, L1Z 1Z1.
                                        </li>
                                        <li>
                                            <i className="ttm-textcolor-skincolor flaticon-email"></i>
                                            <h3>Email:</h3><a href="mailto:hr@digitalspidy.com">hr@digitalspidy.com</a>
                                        </li>
                                    </ul>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="bottom-footer-text">
                    <div className="container">
                        <div className="row">
                            <div className="col-lg-8 col-md-6">
                                <span className="copyright">Copyright © 2016 - 2024 <a href={process.env.PUBLIC_URL + '/'}>Digital Spidy </a>All rights reserved.
                                </span>
                                <ul className="footer-nav-menu">
                                    <li><a href={process.env.PUBLIC_URL + '/'}>Privacy & Policy</a></li>
                                    <li><a href={process.env.PUBLIC_URL + '/'}>Terms & Conditions</a></li>
                                </ul>
                            </div>
                            <div className="col-lg-4 col-md-6 d-lg-flex align-items-center justify-content-between">
                                <form id="subscribe-form" className="subscribe-form">
                                    <div className="mailchimp-inputbox clearfix" id="subscribe-content"> 
                                        <input type="email" name="EMAIL" id="txtemail" placeholder="Enter Your Email Address..." required="" />
                                        <button className="submit ttm-btn btn-inline ttm-btn-size-md ttm-btn-color-skincolor" type="submit">
                                            <i className="fa fa-paper-plane" aria-hidden="true"></i>
                                        </button>
                                    </div>
                                </form>
                            </div>
                        </div>
                    </div>
                </div>
            </footer>
        )
    }
}


