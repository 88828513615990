import React, { Component } from 'react';
import {BrowserRouter as Router} from 'react-router-dom';

export class Menu extends Component {
    state = {
            show: false,
    }
    render() {
        return (
            <Router>
                <nav className="main-menu">         
                    <ul className="menu">
                    <li><a href={process.env.PUBLIC_URL + '/'}>Home</a></li>
                    <li><a href={process.env.PUBLIC_URL + '/about_us'}>About</a></li>
                        <li className="mega-menu-item">
                            <a href={process.env.PUBLIC_URL + '/'} className="mega-menu-link">Services</a>
                            <ul className="mega-submenu">
                                <li><a href={process.env.PUBLIC_URL + '/Consulting_digital_transformation'}>Crafting Success: Consulting & Digital Transformation</a></li>
                                <li><a href={process.env.PUBLIC_URL + '/Elevate_your_workforce_with_next_level_recruitment_solutions'}>Elevate Your Workforce with Next-Level Recruitment Solutions</a></li>
                                <li><a href={process.env.PUBLIC_URL + '/Streamlined_recruitment_solutions'}>Streamlined Recruitment Solutions</a></li>                                
                                <li><a href={process.env.PUBLIC_URL + '/Dynamic_product_development_solutions'}>Dynamic Product Development Solutions</a></li>
                                <li><a href={process.env.PUBLIC_URL + '/Seamless_immigration_assistance'}>Seamless Immigration Assistance</a></li>
                                <li><a href={process.env.PUBLIC_URL + '/Career_guidance'}>Career Guidance</a></li>
                                
                            </ul>
                        </li>
                        <li className="mega-menu-item">
                            <a href={process.env.PUBLIC_URL + '/'} className="mega-menu-link">Industries</a>
                            <ul className="mega-submenu">
                                <li><a href={process.env.PUBLIC_URL + '/Revolutionizing_real_estate_construction_talent_management'}>Revolutionizing Real Estate & Construction Talent Management</a></li>
                                <li><a href={process.env.PUBLIC_URL + '/Banking_finance_recruitment_solutions'}>Banking and Finance Recruitment Solutions</a></li>
                                <li><a href={process.env.PUBLIC_URL + '/Automotive_industrial_engineering_recruitment_solutions'}>Automotive and Industrial Engineering Recruitment Solutions</a></li>
                                <li><a href={process.env.PUBLIC_URL + '/Retail_e_commerce_recruitment_solutions'}>Retail & e-Commerce Recruitment Solutions</a></li>
                                <li><a href={process.env.PUBLIC_URL + '/Clinical_pharmaceutical_industry_recruitment_solutions'}>Clinical & Pharmaceutical Industry Recruitment Solutions</a></li>
                                <li><a href={process.env.PUBLIC_URL + '/Healthcare_insurance_recruitment_solutions'}>Healthcare & Insurance Recruitment Solutions</a></li>
                                <li><a href={process.env.PUBLIC_URL + '/Energy_utilities_recruitment_solutions'}>Energy & Utilities Recruitment Solutions</a></li>
                                <li><a href={process.env.PUBLIC_URL + '/Telecom_media_technology_recruitment_solutions'}>Telecom, Media & Technology Recruitment Solutions</a></li>
                                <li><a href={process.env.PUBLIC_URL + '/Travel_hospitality_recruitment_solutions'}>Travel & Hospitality Recruitment Solutions</a></li>
                            </ul>
                        </li>
                        
                        <li><a href={process.env.PUBLIC_URL + '/contact'}>Contact</a></li>
                    </ul>
                </nav>
            </Router>
        )
        
    }
}

export default Menu;