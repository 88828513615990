import React, { Component } from 'react';
import Header from '../components/layout/Header';
import PageHeader from "../components/layout/PageHeader";
import { Footer } from '../components/layout/Footer';

export class Energy_utilities_recruitment_solutions extends Component {
    render() {
        return (

            <div className="site-main">
                <Header/>
            
                {/* PageHeader */} 
                <PageHeader
                    title="Energy & Utilities Recruitment Solutions"
                    breadcrumb="Industries"
                />
                {/* PageHeader end */}  


                <div className="ttm-row sidebar ttm-sidebar clearfix">
                    <div className="container">
                        {/* row */}
                        <div className="row">
                            <div className="col-lg-8 content-area order-lg-2">
                                {/* ttm-service-single-content-area */}
                                <div className="ttm-service-single-content-area">
                                    <div className="ttm_single_image-wrapper">
                                        <img className="img-fluid" src="images/industries/energy_utilities.jpg" alt="services-img" />
                                    </div>
                                    <div className="ttm-service-description mt-40">
                                        <h3 className="mb-20">Energy & Utilities Recruitment Solutions</h3>
                                        <p>Digital Spidy is committed to delivering comprehensive recruitment solutions customized to meet the distinct requirements of clients within the dynamic oil and energy sector. Our primary focus is on facilitating the acquisition of top-tier talent proficient in cutting-edge exploration technologies, production processes, and development initiatives.</p>
                                        <p>We provide a wide range of recruitment services covering various roles across the energy value chain, including:</p>
                                        <p><b>Upstream: </b>Reservoir engineering, drilling operation management, production optimization, surveillance, and technical support.</p>
                                        <p><b>Midstream: </b>Pipeline optimization, integrity, and energy trading and risk management.</p>
                                        <p><b>Downstream: </b>Refinery information integration, logistics, transportation, and engineering data management.</p>
                                        <p><b>Additional Services: </b>Talent sourcing for engineering data management, enterprise solutions, logistics and fulfillment, aftermarket services, design engineering, analytics and research, and infrastructure management.</p>
                                        <p>Digital Spidy operates on a flexible global delivery model, offering onsite, offshore, right-shore, and near-shore solutions. With a team equipped with strong expertise across the entire value lifecycle, including oil and gas engineering services, forecasting, planning, sourcing, procurement, fulfillment, and aftermarket services, we ensure exceptional results.</p>
                                        <p>Leveraging integrated process analytics and technology expertise, we drive efficiency and reduce costs for our clients.</p>
                                        <p>Partner with Digital Spidy for recruitment solutions that propel your business forward in the energy and utilities sector. Contact us today to learn more about how we can support your staffing needs and drive success.</p>
                                        <div className="ttm-horizontal_sep width-100 mt-25 mb-35"></div>
                                        
                                    </div>
                                </div>
                                {/* ttm-service-single-content-are end */}
                            </div>
                            <div className="col-lg-4 widget-area">
                                <div className="sidebar-right">
                                    <aside className="widget widget-nav-menu">
                                        <ul>
                                            <li><a href={process.env.PUBLIC_URL + '/Revolutionizing_real_estate_construction_talent_management'}>Revolutionizing Real Estate & Construction Talent Management</a></li>                                            
                                            <li><a href={process.env.PUBLIC_URL + '/Banking_finance_recruitment_solutions'}>Banking and Finance Recruitment Solutions</a></li>
                                            <li><a href={process.env.PUBLIC_URL + '/Automotive_industrial_engineering_recruitment_solutions'}>Automotive and Industrial Engineering Recruitment Solutions</a></li>
                                            <li><a href={process.env.PUBLIC_URL + '/Retail_e_commerce_recruitment_solutions'}>Retail & e-Commerce Recruitment Solutions</a></li>
                                            <li><a href={process.env.PUBLIC_URL + '/Clinical_pharmaceutical_industry_recruitment_solutions'}>Clinical & Pharmaceutical Industry Recruitment Solutions</a></li>
                                            <li><a href={process.env.PUBLIC_URL + '/Healthcare_insurance_recruitment_solutions'}>Healthcare & Insurance Recruitment Solutions</a></li>
                                            <li><a href={process.env.PUBLIC_URL + '/Energy_utilities_recruitment_solutions'}>Energy & Utilities Recruitment Solutions</a></li>
                                            <li><a href={process.env.PUBLIC_URL + '/Telecom_media_technology_recruitment_solutions'}>Telecom, Media & Technology Recruitment Solutions</a></li>
                                            <li><a href={process.env.PUBLIC_URL + '/Travel_hospitality_recruitment_solutions'}>Travel & Hospitality Recruitment Solutions</a></li>
                                        </ul>
                                    </aside>
                                    
                                    <aside className="widget widget-banner">
                                        <div className="ttm-col-bgcolor-yes bg-theme-DarkColor ttm-bg p-30 pb-50 pt-45">
                                            <div className="ttm-col-wrapper-bg-layer ttm-bg-layer bg-theme-DarkColor">
                                                <div className="ttm-col-wrapper-bg-layer-inner"></div>
                                            </div>
                                            <div className="layer-content text-center text-theme-WhiteColor">
                                            <div className="ttm-icon ttm-icon_element-onlytxt ttm-icon_element-style-round ttm-icon_element-color-skincolor ttm-icon_element-size-xl">
                                                <i className="far fa-comments"></i></div>
                                                <h3>Do You Need Any Help?</h3>
                                                <div className="ttm-horizontal_sep width-100 mt-25 mb-25"></div>
                                                <ul>
                                                    <li>+971-52 219-4019 </li>
                                                    <li>hr@digitalspidy.com</li>
                                                </ul>
                                                <a className="ttm-btn ttm-btn-size-md ttm-btn-shape-rounded ttm-btn-style-fill 
                                                ttm-btn-color-skincolor" href={process.env.PUBLIC_URL + '/contact'}>Appoinment</a>
                                            </div>
                                        </div>
                                    </aside>       
                                </div>
                            </div>
                        </div>{/* row end */}
                    </div>
                </div>

            <Footer/>
                        
            </div>
          )
      }
   }

export default Energy_utilities_recruitment_solutions;