import React, { Component } from 'react';
import Header from '../components/layout/Header';
import PageHeader from "../components/layout/PageHeader";
import { Footer } from '../components/layout/Footer';

export class Banking_finance_recruitment_solutions extends Component {
    render() {
        return (

            <div className="site-main">
                <Header/>
            
                {/* PageHeader */} 
                <PageHeader
                    title="Banking and Finance Recruitment Solutions"
                    breadcrumb="Industries"
                />
                {/* PageHeader end */}  


                <div className="ttm-row sidebar ttm-sidebar clearfix">
                    <div className="container">
                        {/* row */}
                        <div className="row">
                            <div className="col-lg-8 content-area order-lg-2">
                                {/* ttm-service-single-content-area */}
                                <div className="ttm-service-single-content-area">
                                    <div className="ttm_single_image-wrapper">
                                        <img className="img-fluid" src="images/industries/banking_finance.jpg" alt="services-img" />
                                    </div>
                                    <div className="ttm-service-description mt-40">
                                        <h3 className="mb-20">Banking and Finance Recruitment Solutions</h3>
                                        <p>In the financial services and banking sector, we specialize in offering IT business services in emerging markets. Our objective is to equip clients with local, national, and international talent to effectively manage and expand their businesses.</p>
                                        <p>Our services are designed to assist clients in creating, transforming, and implementing advanced operating models that control costs, manage risk, and facilitate growth.</p>
                                        <p><b>Industry Offering Services: </b>Risk Management: We provide specialized talent with expertise in global financial regulations, offering cost-efficient and risk-mitigating solutions worldwide.</p>
                                        <p><b>Commercial Banking:</b>Our focus lies in delivering talent for commercial banking services, offering smarter processes, advanced analytics, and targeted technology solutions to manage assets annually.</p>
                                        <p><b>Retail Banking: </b>We offer talent for a wide range of retail banking services, including customer service, collections, card industry services, payments, lending, deposits, mortgage, wealth management, brokerage services, and customer acquisition.</p>
                                        <p><b>Analytics Service:</b>Our talent pool covers various aspects of the customer lifecycle, offering services such as market assessment, competitor mapping, risk/operational analytics, and portfolio monitoring to enhance business effectiveness.</p>
                                        <p><b>Financial and Accounting Service: </b>We specialize in finance and accounting services, encompassing end-to-end F&A process management, enterprise risk and compliance, accounting and reporting, treasury and tax advisory services, financial planning, auditing, and accounts payable.</p>
                                        <p><b>We offer talent across all levels of management, providing:</b></p>
                                        <ul>
                                            <li>Executive-level commitment</li>
                                            <li>Flexible engagement models</li>
                                            <li>Single-point-of-contact for account management</li>
                                            <li>Client-dedicated service teams</li>
                                            <li>24x7 recruiting with service delivery</li>                                            
                                        </ul>
                                        <br></br>
                                        <p>Our solution delivery model prioritizes efficiency, minimizing time and costs in the recruitment process to provide clients with the best-fit talent on-demand.</p>
                                        <p>Partner with Digital Spidy for recruitment solutions tailored to the banking and finance sector. Contact us today to discover how we can assist you in finding the talent needed to drive success in your organization.</p>
                                        <div className="ttm-horizontal_sep width-100 mt-25 mb-35"></div>
                                        
                                    </div>
                                </div>
                                {/* ttm-service-single-content-are end */}
                            </div>
                            <div className="col-lg-4 widget-area">
                                <div className="sidebar-right">
                                    <aside className="widget widget-nav-menu">
                                        <ul>
                                            <li><a href={process.env.PUBLIC_URL + '/Revolutionizing_real_estate_construction_talent_management'}>Revolutionizing Real Estate & Construction Talent Management</a></li>                                            
                                            <li><a href={process.env.PUBLIC_URL + '/Banking_finance_recruitment_solutions'}>Banking and Finance Recruitment Solutions</a></li>
                                            <li><a href={process.env.PUBLIC_URL + '/Automotive_industrial_engineering_recruitment_solutions'}>Automotive and Industrial Engineering Recruitment Solutions</a></li>
                                            <li><a href={process.env.PUBLIC_URL + '/Retail_e_commerce_recruitment_solutions'}>Retail & e-Commerce Recruitment Solutions</a></li>
                                            <li><a href={process.env.PUBLIC_URL + '/Clinical_pharmaceutical_industry_recruitment_solutions'}>Clinical & Pharmaceutical Industry Recruitment Solutions</a></li>
                                            <li><a href={process.env.PUBLIC_URL + '/Healthcare_insurance_recruitment_solutions'}>Healthcare & Insurance Recruitment Solutions</a></li>
                                            <li><a href={process.env.PUBLIC_URL + '/Energy_utilities_recruitment_solutions'}>Energy & Utilities Recruitment Solutions</a></li>
                                            <li><a href={process.env.PUBLIC_URL + '/Telecom_media_technology_recruitment_solutions'}>Telecom, Media & Technology Recruitment Solutions</a></li>
                                            <li><a href={process.env.PUBLIC_URL + '/Travel_hospitality_recruitment_solutions'}>Travel & Hospitality Recruitment Solutions</a></li>
                                        </ul>
                                    </aside>
                                    
                                    <aside className="widget widget-banner">
                                        <div className="ttm-col-bgcolor-yes bg-theme-DarkColor ttm-bg p-30 pb-50 pt-45">
                                            <div className="ttm-col-wrapper-bg-layer ttm-bg-layer bg-theme-DarkColor">
                                                <div className="ttm-col-wrapper-bg-layer-inner"></div>
                                            </div>
                                            <div className="layer-content text-center text-theme-WhiteColor">
                                            <div className="ttm-icon ttm-icon_element-onlytxt ttm-icon_element-style-round ttm-icon_element-color-skincolor ttm-icon_element-size-xl">
                                                <i className="far fa-comments"></i></div>
                                                <h3>Do You Need Any Help?</h3>
                                                <div className="ttm-horizontal_sep width-100 mt-25 mb-25"></div>
                                                <ul>
                                                    <li>+971-52 219-4019 </li>
                                                    <li>hr@digitalspidy.com</li>
                                                </ul>
                                                <a className="ttm-btn ttm-btn-size-md ttm-btn-shape-rounded ttm-btn-style-fill 
                                                ttm-btn-color-skincolor" href={process.env.PUBLIC_URL + '/contact'}>Appoinment</a>
                                            </div>
                                        </div>
                                    </aside>       
                                </div>
                            </div>
                        </div>{/* row end */}
                    </div>
                </div>

            <Footer/>
                        
            </div>
          )
      }
   }

export default Banking_finance_recruitment_solutions;